@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%); /* Start from left outside the view */
    opacity: 0;
  }
  85% {
    transform: translateX(0); /* End at the original position */
    opacity: 1;
  }
  100% {
    transform: translateX(5px); /* Wiggle to the right */
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(-100%); /* Start from right outside the view */
    opacity: 0;
  }
  85% {
    transform: translateX(0); /* End at the original position */
    opacity: 1;
  }
  100% {
    transform: translateX(-5px); /* Wiggle to the left */
    opacity: 1;
  }
}

.slide-in-left {
  animation: slideInFromLeft 1.2s cubic-bezier(0.22, 1, 0.36, 1) forwards; /* Smooth deceleration */
}

.slide-in-right {
  animation: slideInFromRight 1.2s cubic-bezier(0.22, 1, 0.36, 1) forwards; /* Smooth deceleration */
}


.home-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Start aligning items from the left */
  /* padding: 0px 12em 0px 12em; Adjusted padding */
  /* margin-bottom: 250px; */
  min-height: 100vh; /* Ensures section fills the viewport */
  background: var(--background-color); /* Light grey background */
  color: var(--heading-and-text-color); /* Dark grey text for contrast */
  /* position: relative; */
}

.text-content{
  margin-left: 20px;
}
.text-content h1 {
  font-size: 45pt;
  margin-top: -100px;
}

.text-content h2 {
  font-size: 32pt;
  margin-top: -40px;
}

.text-content p {
  display: block;
  font-size: 15pt;
  margin-top: 20px;
  margin-right: 100px;
  text-align: justify;
  text-align-last: left; /* Aligns the last line to the left */
}

.text-content h2 span{
  color: var(--second-heading-color);
}
.button-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  gap: 20px; /* Space between buttons */
  position: relative;
}
.button{
  padding: 12px 24px; /* Adequate padding but not too wide */
  background-color: var(--heading-and-text-color); /* Dark blue from your palette */
  color: var(--background-color); /* Text color */
   border: none; /* No border */
  border-radius: 30px; /* Fully rounded edges */
  cursor: pointer; /* Cursor indicates clickable */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions for interactions */
  align-self: center; /* Center button within the form */
}

.button:hover {
  background-color: var(--second-heading-color); /* Lighter grey on hover */
  transform: scale(1.05);
}

.person {
  align-items: center;
  display: flex;
  flex-direction: column;

}

.container {
  border-radius: 50%;
  height: 312px;
  -webkit-tap-highlight-color: transparent;
  transform: scale(0.85);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 400px;
}

.container:hover {
  transform: scale(0.9);
}

.container-inner {
  clip-path: path("M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z");
  position: relative;
  transform-origin: 50%;
  top: -200px;
}

.circle {
  background-color: var(--heading-and-text-color);
  border-radius: 50%;
  height: 380px;
  left: 10px;
  position: absolute;
  top: 210px;
  width: 380px;
}

.img {
  position: relative;
  transform: translateY(20px) scale(1.15);
  transform-origin: 50% bottom;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.container:hover .img {
  transform: translateY(0) scale(1.3);
}

.img3 {
  left: -36px;
  top: -44px;
  width: 466px;
}

@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
    text-align: center;
    margin: 150px;
    min-height: 0vh; /* Ensures section fills the viewport */
 
    /* padding: 50px 2em;  Reduced padding for smaller screens */
  }
  
.text-content{
  margin: 20px;
}

.text-content h1 {
  margin:10px auto;
  font-size: 25pt;
}

.text-content h2 {
  margin: 10px auto;
  font-size: 20pt;
 }

.text-content p {
  margin: 10px auto;
  font-size: 12pt;
}


.person {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 13px;

}


.container {
  border-radius: 50%;
  height: 312px;
  -webkit-tap-highlight-color: transparent;
  transform: scale(0.48);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 400px;
}
.container:hover {
  transform: scale(0.54);
}
.container-inner {
  clip-path: path(
    "M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z"
  );
  position: relative;
  transform-origin: 50%;
  top: -200px;
}
.circle {
  background-color: var(--heading-and-text-color);
  border-radius: 50%;
  cursor: pointer;
  height: 380px;
  left: 10px;
  pointer-events: none;
  position: absolute;
  top: 210px;
  width: 380px;
}

.img3 {
  left: -36px;
  top: -44px;
  width: 466px;
}

.button-container{
  margin-top: 40px;
}

}

@media (max-width: 480) {
  .home-container {
    flex-direction: column;
    text-align: center;
    margin: 0;
    min-height: 100vh;
  }

  .text-content{
    margin: 0px;
  }

  .text-content h1 {
    margin:0px;
  }
  
  .text-content h2 {
    margin: 0px;
   }
  
  .text-content p {
    margin:0px;
  }
  
  
  .person {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 11px;
  
  }
  
.container {
  border-radius: 50%;
  height: 312px;
  -webkit-tap-highlight-color: transparent;
  transform: scale(0.85);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 400px;
}

.container:hover {
  transform: scale(0.9);
}

.container-inner {
  clip-path: path("M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z");
  position: relative;
  transform-origin: 50%;
  top: -200px;
}

.circle {
  background-color: var(--heading-and-text-color);
  border-radius: 50%;
  height: 380px;
  left: 10px;
  position: absolute;
  top: 210px;
  width: 380px;
}

.img {
  position: relative;
  transform: translateY(20px) scale(1.15);
  transform-origin: 50% bottom;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.container:hover .img {
  transform: translateY(0) scale(1.3);
}

.img3 {
  left: -36px;
  top: -44px;
  width: 466px;
}
.button-container{
  margin-top: 30px;
}


}

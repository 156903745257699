.skills-container {
    display: flex;
    justify-content: center; /*Center the skill categories horizontally */
    align-items: flex-start;/* Align items to the top */
    flex-direction: row;
    min-height: 100vh;
    background-color: var(--background-color); /* Default background */
    text-align: center; /* Ensure text is centered */
    flex-wrap: wrap; /* Allow categories to wrap on smaller screens */
    
}

.skills-container h2 {
    width: 100%;
    color: var(--heading-and-text-color); /* Dark blue color from your palette */
    font-size: 2.8rem; /* Large font size for header */
    margin-bottom: 0; /* Reduced space below the header */
    text-shadow: 1px 1px 4px rgba(0,0,0,0.2);
}

.skill-category h3 {
    height: 80px; /* Fixed height for all h3 tags */
    color: var(--second-heading-color); /* Reddish color for category titles */
    /* margin-bottom: 10px; Reduced space below the category title */
    font-size: 1.  1rem; /* Size for readability */
    display: flex; /* Use flexbox to vertically center text */
    /* align-items: center; Center text vertically */
    justify-content: center; /* Center text horizontally */
    /* margin-top: -6em; */
}

.skill-category {
    /* flex: 1; Grow to fill space */
    width: 120px; /*Minimum width for each category */
    /* margin: 5px; Space around each category */
    padding: 10px;
    border-radius: 15px;
    /* margin-top: 0; */
    
    /*background-color: var(--card-background-color);  Lighter background for each category 
    box-shadow: 0 2px 10px rgba(0,0,0,0.1); /* Soft shadow for depth */
}

.skill-tags {
    display: flex;
    flex-direction: column; /* Stack tags vertically */
    align-items: center; /* Center tags within category */
    gap: 15px; /* Space between tags */
}

.skill-tag {
    display: inline-block;
    width: 85%; /* Set fixed width for consistency */
    text-align: center; /* Center text within tags */
    background-color: var(--heading-and-text-color); /* Dark blue */
    border-radius: 50px;
    padding: 8px;
    font-size: 0.75rem; /* Standard font size for tags */
    color: var(--background-color); /* White text for contrast */
    white-space: nowrap; /* Prevents text from wrapping to the next line */
    overflow: hidden; /* Hide text that overflows */
    text-overflow: ellipsis; /* Add ellipsis if text is too long */
}

@media (max-width: 768px) {
    .skills-container {
        justify-content: center; /*Center the skill categories horizontally */
    
        flex-direction: column;
        align-items: center; /* Center everything on smaller screens */
        padding: 10px; /* Adjust padding for smaller screens */
    }

    .skill-category {
        width: 100%; /* Full width for mobile view */
        margin-bottom: 30px;
    }

    .skill-category h3 {
        height: 20px; /* Fixed height for all h3 tags */
    }

    .skill-tag {
        width: 40%;
        padding: 6px;
    }

}

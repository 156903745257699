:root {
    /* Light theme colors */
    --background-color: #EEE2DC;
    --heading-and-text-color: #123C69;
    --second-heading-color: #AC3B61;
    --card-background-color: #EDC7B7;
    --navbar-text-color: #BAB2B5;
    --card-animation-color: rgba(172, 59, 97, 0.2);
    

    /* Dark theme colors */
}
    [data-theme="dark"] {
        --background-color: #2b2b2b;
        --heading-and-text-color: #D6E4FF;
        --second-heading-color: #FFD0CC;
        --card-background-color: #423937;
        --navbar-text-color: #5C5458;
        --card-animation-color: rgba(255, 105, 135, 0.3);
    }
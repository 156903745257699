.theme-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  .theme-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  


.navbar {
    display: flex;
    justify-content: space-between; /* Adjusted to space-between to include home link on the left */
    padding: 0px; /* Added padding to ensure spacing on the sides */
    height: 55px; /* Height of the navbar */
    background-color: var(--background-color); /* Background color */
    position: sticky;
    top: 0;
    z-index: 1000;
}

.navbar .home-link {
    font-weight: bold; /* Bold to distinguish home link */
    color: var(--heading-and-text-color); /* Same color theme */
    transition: color 0.3s, background-color 0.3s; /* Consistent transitions */
}

.nav-links {
    display: flex;
}

.navbar a {
    color: var(--navbar-text-color); /* Text color */
    font-size: 21px; /* Font size */
    padding: 14px 15px; /* Padding for clickable area */
    border-radius: 0px; /* Rounded corners */
    transition: color 0.3s, background-color 0.3s; /* Smooth transition for hover effects */
}

.navbar a:hover {
    background-color: var(--second-heading-color); /* Hover background color */
    color: var(--heading-and-text-color); /* Text color on hover */
}

.navbar .home-link:hover{
    background-color: transparent;
    color:  var(--second-heading-color);
    cursor: default;
    transform: scale(1.2);
}

.navbar .home-link.active{
    border-bottom: 0px; /* RemoveActive state underline */
}
.navbar a.active {  
    border-bottom: 3px solid var(--second-heading-color); /* Active state underline */
}

@media (max-width: 768px) {
    .navbar {
        justify-content: space-between; /* Center the links for mobile */
        height: 45px;

    }
    .navbar a {
        margin: 0px; /* Smaller margin on mobile */
        padding: 5px; /*Smaller padding on mobile*/
        font-size: 15px;
    }
    .nav-links {
        flex-direction: row; /* Stack links vertically on mobile */
    }
}

@media (max-width: 480px) {
    .navbar {
        justify-content: space-between; /* Center the links for mobile */
        height: 35px;

    }
    .navbar a {
        margin: 0px; /* Smaller margin on mobile */
        padding: 5px; /*Smaller padding on mobile*/
        font-size: 13px;
    }
    .nav-links {
        flex-direction: row; /* Stack links vertically on mobile */
    }
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  font-family: 'Montserrat', sans-serif;
 }
 
a {
 text-decoration: none;
}
.contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--background-color);
    padding: 0px 20px;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes popIn {
    0% { transform: scale(0.5); opacity: 0; }
    60% { transform: scale(1.1); opacity: 1; }
    100% { transform: scale(1); }
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    animation: popIn 0.6s ease-in-out forwards;
}

.social-icons a {
    font-size: 24px;
    color: var(--heading-and-text-color);
    transition: color 0.3s, transform 0.3s;
}

.social-icons a:hover {
    color: var(--second-heading-color);
    transform: scale(1.2);
}

.contact-form {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    background-color: var(--card-background-color);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    animation: slideUp 0.4s ease-out;
}

@keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}


.contact-form-container h2 {
    color: var(--heading-and-text-color); /* Dark blue color */
    margin-bottom: 30px; /* More space between title and form */
    font-size: 2.8rem; /* Slightly larger for prominence */
    
}

.contact-form label {
    margin-bottom: 8px;
    color: var(--heading-and-text-color);
}

.contact-form input,
.contact-form textarea {
    padding: 12px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: none;
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.08);
}

.contact-form input:focus,
.contact-form textarea:focus {
    box-shadow: 0 0 0 2px var(--second-heading-color); /* Highlight focus with a border */
}

.contact-form button {
    padding: 12px 24px; /* Adequate padding but not too wide */
    background-color: var(--heading-and-text-color); /* Dark blue from your palette */
    color: var(--card-background-color); /* Text color */
    /* font-weight: bold; Make text bold */
    font-family: 'Montserrat', sans-serif;
    border: none; /* No border */
    border-radius: 30px; /* Fully rounded edges */
    cursor: pointer; /* Cursor indicates clickable */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transitions for interactions */
    align-self: center; /* Center button within the form */
}

.contact-form button:hover {
    background-color: var(--second-heading-color); /* Color change on hover */
    transform: scale(1.05); /* Slightly increase size on hover */
}

